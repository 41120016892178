body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

button{
  background-color: #0fafbf;
  color:#fff;
  border: 0;
  width: 120px;
  height: 43px;
  border-radius: 9px;
  margin-top: 40px;
  cursor: pointer;
}

.main-logo{
  background-image: url("../public/images/purplez-logo.png");
  height: 70px;
  width: 100%;
  margin-top: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 40px;
}

.home p{
  width: max-content;
  margin: auto;
  margin-bottom: 15px;
  max-width: 90%;
  text-align: center;
}

.home h1{
  text-align: center;
  margin: auto;
  font-size: 24px;
  font-weight: 600;
  max-width: 90%;
}

.home-cards{
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 20px;
}

.home-card{
  flex: calc((100% - 148px) / 3);
  flex-grow: 0;
  border-radius: 20px;
  overflow: hidden;
  padding: 15px;
  border: 3px solid #0fafbf;
}

.home-card.deactive{
  border: 1px solid #999;
}

.home-card.deactive .home-card-button{
  background-color: #999;
}

.home-card.deactive .home-card-tag{
  color: #555;
}

.home-card-image{
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-card-image.website{
  background-image: url('../public/images/website.png');
}

.home-card-image.marketing{
  background-image: url('../public/images/marketing.png');
}

.home-card-image.onboarding{
  background-image: url('../public/images/onboarding.png');
}

.home-card-title{
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
}
.home-card-description{
  color:#333;
  margin-top: 10px;
  margin-bottom: 15px;
}

.home-card-tag{
  display: inline-block;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  line-height: 0;
}

.home-card-button{
  height: 40px;
  background-color: #7b2a7c;
  border-radius: 5px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  float: right;
}

footer{
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  border-top: 1px solid #999;
  padding: 20px 0;
}

footer .copyright{
  margin-left: auto;
}

footer a{
  color: #000 !important;
}

footer a:hover span{
  text-decoration: underline;
}

footer a span{
  color: #7b2a7c;
}

.form{
  display: flex;
  flex:1;
  flex-wrap: wrap;
}

.sidebar{
  width: 450px;
  background-color: #7b2a7c;
  position: relative;
  display: inline-block;
  min-height: 100vh;
}

.logo{
  background-image: url("../public/images/logo.png");
  height: 50px;
  width: calc(100% - 40px);
  margin-top: 30px;
  margin-left: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.navbar{
  display: block;
  margin:0 40px;
  color:#fff;
  padding-top: 50px;
}

.navtop-enter{
  opacity: 0;
  margin-left: -20px;
}

.navtop-enter.navtop-enter-active {
  opacity: 1;
  margin-left: 0;
  transition: opacity 0.2s ease-out, margin 0.2s ease-out;
}

.navtop-exit {
  opacity: 1;
  margin-left: 0;
}

.navtop-exit.navtop-exit-active {
  opacity: 0;
  margin-left: 20px;
  transition: opacity 0.2s ease-out, margin 0.2s ease-out;
}

.navbar h2{
  font-size: 30px;
}

.navbar>ul{
  padding-left: 0;
}

.navbar>ul li{
  color:rgba(256,256,256,0.4);
  list-style-type: none;
  cursor: pointer;
  padding:30px 0;
  position: relative;
  transition: color 0.2s  ;
}

.navbar>ul li:not(.navbar-first):before{
  position: absolute;
  width:2px;
  height:40px;
  left: 12px;
  border-left: 2px dashed rgba(256,256,256,0.4);
  top:-20px;
  content: "";
  transition: border-color 0.2s;
}

.navbar>ul li.active, .navbar>ul li:hover{
  color:#fff;
}

.navbar>ul li.active:before{
  border-color: #fff;
}

.navbar>ul li>span{
  border: 1px solid rgba(256,256,256,0.4);
  border-radius: 100%;
  margin-right: 10px;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  transition: border-color 0.2s;
}

.navbar>ul li.active>span, .navbar>ul>li:hover>span{
  border-color: #fff;
}

.main{
  width: calc(100% - 650px);
  display:inline-flex;
  vertical-align: top;
  align-items:center;
  padding: 30px 100px;
  overflow: auto;
  min-height: calc(100vh - 60px);
  max-width: 800px;
}

.main-enter{
  opacity: 0;
}

.main-enter.main-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.main-exit {
  opacity: 1;
}

.main-exit.main-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.icon{
  background-color: #fff2d9;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 100%;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}
.icon-waving{
  background-image: url("../public/images/Waving\ Hand\ Emoji.png");
}
.icon-finger{
  background-image: url("../public/images/Index\ Finger\ Emoji.png");
}
.icon-victory{
  background-image: url("../public/images/Victory\ Emoji.png");
}
.icon-writing{
  background-image: url("../public/images/Writing\ Hand\ Emoji.png");
}
.icon-call{
  background-image: url("../public/images/Call\ Me\ Emoji.png");
}
.icon-crossed{
  background-image: url("../public/images/Fingers\ Crossed\ Emoji.png");
}

.main h1, .main h2{
  font-size: 24px;
  color:#7b2a7c;
}
.main p{
  color: #999;
}

.main .error{
  color: rgb(255, 68, 68);
  font-weight: 600;
}

.back{
  color: #666;
  cursor: pointer;
  margin-right: 40px;
}
.back:hover{
  text-decoration: underline;
}

.form-container{
  margin: 30px 0 10px 0;
}

.input-container{
  display: inline-block;
  margin: 5px 0;
  vertical-align: top;
  margin-right: 20px;
  max-width: 340px;
  width: calc((100% - 20px) / 2);
}

.input-container.full{
  max-width: 700px;
  width: 100%;
}

.input-container.color, .input-container.radio{
  width: unset;
}

.col2{
  margin-right: 0;
}

input[type=text], textarea{
  height: 40px;
  width: calc(100% - 40px);
  border-radius: 7px;
  border: 1px solid #e5e5e5;
  outline: unset;
  padding: 0 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 14px;
}

input[type=text].redoutline, textarea.redoutline, input[type="color"].redoutline{
  outline: 3px solid red;
}

input[type=text]:focus, textarea:focus{
  outline: 3px solid #0fafbf;
}

input[type=text]::placeholder, textarea::placeholder{
  color:#aaa;
  margin-left: 5px;
}

textarea{
  resize: none;
  height: 80px;
  width: calc(100% - 40px);
  padding:20px;
}

input[type=color]{
  border:3px solid #e5e5e5;
  border-radius: 12px;
  height: 40px;
  width: 40px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

.input-title{
  display: block;
  color:#666;
  font-size: 13px;
  margin: 20px 0 10px 0;
}

.chackbox{
  cursor: pointer;
  margin-right: 30px;
  display: block;
  width: max-content;
}
.chackbox input[type=checkbox]{
  display: none;
}
.checkbox-box{
  width: 24px;
  height: 24px;
  margin: 5px 0;
  border: 3px solid #e5e5e5;
  display: inline-block;
  border-radius: 12px;
  vertical-align: middle;
  margin-right: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  transition: border 0.2s, background-color 0.2s;
}
.chackbox:hover .checkbox-box{
  border: 3px solid #0fafbf;
}
.chackbox input[type=checkbox]:checked ~ .checkbox-box{
  background-color: #0fafbf;
  border-color: #0fafbf;
}
.chackbox input[type=checkbox]:checked ~ .checkbox-box::after{
  content: "✓";
  color:#fff;
}

.radiobox{
  cursor: pointer;
  margin-right: 30px;
  vertical-align: top;
}
.radiobox input[type=radio]{
  display: none;
}
.radiobox-box{
  width: 24px;
  height: 24px;
  margin: 5px 0;
  border: 3px solid #e5e5e5;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  transition: border 0.2s, background-color 0.2s;
}
.radiobox:hover .radiobox-box{
  border: 3px solid #0fafbf;
}
.radiobox input[type=radio]:checked ~ .radiobox-box{
  border-color: #0fafbf;
}
.radiobox input[type=radio]:checked ~ .radiobox-box::after{
  content: "";
  background-color: #0fafbf;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 100%;
  margin:4px 0 0 4px;
}

@media only screen and (max-width: 1000px) {
  .sidebar{
    width: 300px;
  }
  .main{
    width: calc(100% - 500px);
  }
  .input-container{
    width: 100%;
    max-width: unset;
  }
}
@media only screen and (max-width: 900px) {
  .home-card{
    flex: calc((100% - 92px) / 2);
    flex-grow: 0;
  }
}
@media only screen and (max-width: 850px) {
  .main{
    padding: 30px;
    width: calc(100% - 360px);
  }
}
@media only screen and (max-width: 700px) {
  .logo{
    margin-left: 30px;
  }
  .sidebar{
    width: 100%;
    min-height: unset;
    padding-bottom: 20px;
  }
  .navbar{
    padding-top: 5px;
    margin-left: 30px;
  }
  .navbar>ul{
    display: none;
  }
  .main{
    width: calc(100% - 60px);
    min-height: unset;
  }
}
@media only screen and (max-width: 570px) {
  .home-card{
    flex: calc(100% - 36px);
  }
}